<template>
  <div class="setnew">

    <b-container>
      <b-row class="justify-content-md-center">
        <b-col md="6" class="bg-white mt-5 p-4 colcont">

          <template v-if="!loading && !success">
            <h2>Sorry, your request is either expired or invalid.</h2>
            <p>You can start the password reset <a href="/">process here</a>.</p>
          </template>

          <template v-else-if="loading" class="text-center p-4">
            <img src="./../assets/loading.gif" height="42px"/>
          </template>

          <template v-else>

            <b-alert variant="danger" v-show="validate('misc')">{{ errors.misc }}</b-alert>

            <template v-if="stage===1">

              <h2>New Password</h2>

              <p>Choose a new secure and memorable password for your STUNii account.</p>

              <b-form @submit.prevent="doSave">
                <b-form-group
                    id="password1group"
                    label="New password"
                    label-for="password1"
                >
                  <b-form-input
                      id="password1"
                      type="password"
                      v-model="password1"
                      required
                  ></b-form-input>
                  <b-form-invalid-feedback :state="validate('password1')">
                    {{ errors.password1 }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <b-form-group
                    id="password2group"
                    label="New password again"
                    label-for="password2"
                >
                  <b-form-input
                      id="password2"
                      type="password"
                      v-model="password2"
                      required
                  ></b-form-input>
                  <b-form-invalid-feedback :state="validate('password2')">
                    {{ errors.password2 }}
                  </b-form-invalid-feedback>
                </b-form-group>
                <b-button type="submit" variant="primary">Save password <span v-if="process"
                                                                              class="spinner-border spinner-border-sm"
                                                                              role="status" aria-hidden="true"></span>
                </b-button>
              </b-form>
            </template>

            <template v-else-if="stage===2">
              <h2>Amazing, all done</h2>
              <p>You can login to the STUNii app with your new password now.</p>
              <p>Got a question? <a href="mailto:hello@STUNii.com">Drop us an email!</a></p>

              <div class="text-center">
                <hr>
                <h4>Need the app?</h4>
                <a href="https://play.google.com/store/apps/details?id=obllivionsoft.djole.nis.rs.stusdeals&hl=en_GB&gl=US"><img
                    class="store m-2"
                    src="./../assets/google.png" alt="Google Play" height="50px"/></a>
                <a href="https://apps.apple.com/gb/app/stunii-unbeatable-discounts/id1281476467"><img class="store m-2"
                                                                                                      src="./../assets/apple.png"
                                                                                                      alt="App Store"
                                                                                                      height="50px"/></a>
              </div>
            </template>
          </template>
        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
export default {
  name: 'SetNew',
  data: function () {
    return {
      loading: true,
      process: false,
      success: false,
      stage: 1,
      userID: '',
      token: '',
      password1: '',
      password2: '',
      errors: {}
    }
  },
  methods: {
    validate: function (error) {
      if (this.errors[error]) {
        return false;
      }
      return null;
    },
    loadRequest: function () {

      let vm = this

      fetch(`https://api.stunii.com/password/reset/${this.$route.params.userID}/${this.$route.params.token}`, {
        method: 'get'
      })
          .then(result => result.json())
          .then(data => {
            if (data.success) {
              vm.loading = false
              vm.success = true
            } else {
              vm.loading = false
              vm.success = false
            }
          })
          .catch((error) => {
            console.error('Error:', error);
          });

    },
    doSave: function () {

      let vm = this

      vm.process = true

      fetch(`https://api.stunii.com/password/reset/${this.$route.params.userID}/${this.$route.params.token}`, {
        method: 'post',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({password1: this.password1, password2: this.password2})
      })
          .then(result => result.json())
          .then(data => {

            if (data.errors) {
              this.errors = data.errors
              this.stage = 1
            } else {
              this.errors = {}
              this.stage = 2
            }

            vm.process = false

          })
          .catch((error) => {

            this.stage = 1;
            this.errors = {misc: "Error occurred (err)"}

          });

    }

  },
  created() {
    this.loadRequest()
  }
}
</script>