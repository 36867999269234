<template>
  <div class="reset">

    <b-container>
      <b-row class="justify-content-md-center">
        <b-col md="6" class="bg-white mt-5 p-4 colcont">

          <template v-if="stage===1">

            <h2>Reset your password</h2>
            <p>Forgot your password? No worries, enter your email address below and we'll get it sorted for you.</p>

            <b-alert variant="danger" v-show="validate('misc')">{{ errors.misc }}</b-alert>

            <b-form @submit.prevent="goReset">
              <b-form-group
                  id="emailgroup"
                  label="Email address:"
                  :state="validate('email')"
                  label-for="email"
              >
                <b-form-input
                    id="email"
                    type="email"
                    v-model="email"
                    required
                ></b-form-input>
                <b-form-invalid-feedback :state="validate('email')">
                  {{ errors.email }}
                </b-form-invalid-feedback>
              </b-form-group>
              <b-button type="submit" variant="primary">Reset password <span v-if="process"
                                                                             class="spinner-border spinner-border-sm"
                                                                             role="status" aria-hidden="true"></span>
              </b-button>

            </b-form>

            <p class="mt-3">Got a question? <a href="mailto:hello@stunii.com">Get in touch</a></p>

            <div class="text-center">
              <hr>
              <h4>Need the app?</h4>
              <a href="https://play.google.com/store/apps/details?id=obllivionsoft.djole.nis.rs.stusdeals&hl=en_GB&gl=US"><img
                  class="store m-2"
                  src="./../assets/google.png" alt="Google Play" height="50px"/></a>
              <a href="https://apps.apple.com/gb/app/stunii-unbeatable-discounts/id1281476467"><img class="store m-2"
                                                                                                    src="./../assets/apple.png"
                                                                                                    alt="App Store"
                                                                                                    height="50px"/></a>
            </div>

          </template>

          <template v-else>

            <h2>Next step</h2>

            <p>If the email address you provided ({{ email }}) matches a STUNii account, we'll send you a email with the
              next step.</p>
            <p>It might take a few minutes to arrive, and don't forget to keep an eye on your spam/junk/filters!</p>
            <p><a href="mailto:hello@stunii.com">Get in touch</a> if you need more assistance.</p>

            <p>Wrong email address? <a href="#" @click="reset()">Try again</a></p>

            <div class="text-center">
              <hr>
              <h4>Need the app?</h4>
              <a href="https://play.google.com/store/apps/details?id=obllivionsoft.djole.nis.rs.stusdeals&hl=en_GB&gl=US"><img
                  class="store m-2"
                  src="./../assets/google.png" alt="Google Play" height="50px"/></a>
              <a href="https://apps.apple.com/gb/app/stunii-unbeatable-discounts/id1281476467"><img class="store m-2"
                                                                                                    src="./../assets/apple.png"
                                                                                                    alt="App Store"
                                                                                                    height="50px"/></a>
            </div>

          </template>

        </b-col>
      </b-row>
    </b-container>

  </div>
</template>

<script>
export default {
  name: 'Reset',
  data: function () {
    return {
      loading: false,
      stage: 1,
      process: false,
      email: null,
      errors: {}
    }
  },
  methods: {
    validate: function (error) {
      if (this.errors[error]) {
        return false;
      }
      return null;
    },
    reset: function () {
      this.stage = 1
      this.email = ''
    },
    goReset: function () {

      let vm = this

      vm.process = true

      fetch('https://api.stunii.com/password/reset', {
        method: 'post',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json; charset=utf-8'
        },
        body: JSON.stringify({email: this.email})
      })
          .then(result => result.json())
          .then(data => {

            if (data.errors) {
              this.errors = data.errors
              this.stage = 1
              vm.process = false
            } else {
              this.errors = {}
              this.stage = 2
              vm.process = false
            }

          })
          .catch((error) => {

            this.stage = 1;
            this.errors = {misc: "Error occurred (err)"}

          });

    }
  }
}
</script>

<style>
.colcont {
  border-radius: 10px;
}
</style>