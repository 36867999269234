import Vue from 'vue'
import VueRouter from 'vue-router'
import Reset from "@/views/Reset.vue"
import SetNew from "@/views/SetNew";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Reset',
        component: Reset
    },
    {
        path: '/:userID/:token',
        name: 'SetNew',
        component: SetNew
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router